import { observer } from "mobx-react";
import React from "react";
import {
  NavigateToFunctions,
  processSurveyParams,
} from "../../../../routes/RoutesHelper";
import {
  FoundationColorTokens,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { Dialog } from "@surya-digital/leo-reactjs-material-ui";
import { AlertCircle } from "lucide-react";
import {
  ICON_SIZE,
  SINGLE_SPACING,
  getIconProps,
} from "@pulse/shared-components";
import { Stack, Typography } from "@mui/material";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import { QuestionDetailsStore } from "../../store/QuestionDetailsStore";

interface SurveyIsLiveConfirmationDialogProps {
  showSurveyLiveConfirmationDialog: boolean;
  setShowSurveyLiveConfirmationDialog: (
    value: React.SetStateAction<boolean>,
  ) => void;
  setShowAddOrUpdateSuccessfulDialog: (
    value: React.SetStateAction<boolean>,
  ) => void;
  setSelectedItemQuestionId: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  selectedQuestionItemId: string | null;
  tokens: FoundationColorTokens<string>;
  t: TFunction;
  navigateTo: NavigateToFunctions;
  questionDetailsStore: Instance<typeof QuestionDetailsStore>;
  spacing: Spacing;
}

export const SurveyIsLiveConfirmationDialog = observer(
  ({
    setShowSurveyLiveConfirmationDialog,
    showSurveyLiveConfirmationDialog,
    setShowAddOrUpdateSuccessfulDialog,
    setSelectedItemQuestionId,
    selectedQuestionItemId,
    tokens,
    t,
    navigateTo,
    questionDetailsStore,
    spacing,
  }: SurveyIsLiveConfirmationDialogProps): React.ReactElement => {
    return (
      <Dialog
        open={showSurveyLiveConfirmationDialog}
        title={t("surveys.addSurveyQuestion.surveyIsLiveTitle")}
        contentPadding={spacing.spaceLG}
        disableBackdropClick={true}
        secondaryButtonText={t("common.close")}
        onSecondaryButtonClick={() =>
          setShowSurveyLiveConfirmationDialog(false)
        }
        primaryButtonText={t("common.proceed")}
        onPrimaryButtonClick={async (): Promise<void> => {
          setShowSurveyLiveConfirmationDialog(false);
          if (selectedQuestionItemId) {
            await questionDetailsStore.updateQuestion(
              selectedQuestionItemId,
              t,
            );
            if (questionDetailsStore.isSuccessfulDialogOpen) {
              setShowAddOrUpdateSuccessfulDialog(true);
            }
          } else {
            processSurveyParams(async (surveyId, projectId) => {
              const newlyAddedQuestionId =
                await questionDetailsStore.addNewQuestion(
                  surveyId,
                  projectId,
                  t,
                );
              if (newlyAddedQuestionId !== undefined) {
                setSelectedItemQuestionId(newlyAddedQuestionId);
              }
              if (questionDetailsStore.isSuccessfulDialogOpen) {
                setShowAddOrUpdateSuccessfulDialog(true);
              }
            }, navigateTo);
          }
        }}
      >
        <Stack
          width="100%"
          alignItems="center"
          gap={spacing.spaceXS}
          padding={spacing.spaceXL}
        >
          <AlertCircle {...getIconProps(tokens.iconWarning, ICON_SIZE.large)} />
          <Typography
            sx={{
              color: tokens.labelWarning,
              textAlign: "center",
            }}
          >
            {t("surveys.addSurveyQuestion.surveyIsLiveDescription") +
              { SINGLE_SPACING } +
              t("surveys.addSurveyQuestion.proceedConfirmationDescription")}
          </Typography>
        </Stack>
      </Dialog>
    );
  },
);
