/**Initially we created a single file will all the constant values. But defining enums and consts in a single file throws the following error -

```
Module parse failed: Unexpected token (15:7)
 File was processed with these loaders:
* ../../node_modules/source-map-loader/dist/cjs.js
You may need an additional loader to handle the result of these loaders.
> export enum SURVEY_PRIVILEGES {
|   createSurveyQuestion = "CREATE_SURVEY_QUESTION",
|   deleteSurveyQuestion = "DELETE_SURVEY_QUESTION",
```

So far, the only solution was to create a Constants folder and define each enum in a separate file. For example - UserPrivilegesEnum.ts
**/

// Used in the rootContainer component.
export const VIEWPORT = {
  MAX_WIDTH: "100vw",
  MAX_HEIGHT: "100vh",
};

// Used in the DropDownForTopNavBar component.
export const CHEVRON_ICON_SIZE = "24px";
export const DROPDOWN_ANIMATION_TIMING_IN_MS = 30;

// Used in UnassignSurveyRespondentDialog component.
export const TOTAL_COUNTDOWN_TIME_IN_SEC = 5;
export const COUNTDOWN_INTERVAL_IN_MS = 1000;
export const END_OF_COUNTDOWN = 0;

// Mocks.
export const EXECUTION_DELAY_IN_MS = 2000;

export const MOCK = {
  auth: false,
  surveyQuestions: false,
  respondentDetails: false,
  surveyRespondent: false,
  surveyResults: false,
  reportDetails: false,
  survey: false,
  surveyDetails: false,
  projectList: false,
  surveySettings: false,
  spocManagement: false,
  boManagement: false,
  userAccess: false,
  manageUsers: false,
  surveyTranslations: false,
  auditLogs: false,
};

export const URL_DELIMITER = "/";
export const EMPTY_CHARACTER = "";
// Used in date formatting functions.
export const DATE_STRING_YEAR_SEPARATOR = ",";
export const SINGLE_SPACING = " ";
export const NAME_AND_EMAIL_SEPARATOR = "-";
export const ZERO_PERCENTAGE_VALUE = 0.0;
export const HUNDRED_PERCENTAGE_VALUE = 100.0;
export const ZERO_VALUE = 0;

// Used in the Survey Results Breakdown panel
export const SURVEY_RESULTS_BREAKDOWN_BAR_WIDTH_IN_PX = 400;

// Axios APIClient configuration constants.
// Timeout after 2 mins.
export const TIMEOUT_IN_MS = 120000;

// Status codes used for network calls
export const STATUS_CODE = {
  NOT_FOUND: 404,
  OK: 200,
  TIMEOUT: 408,
  NETWORK_ERROR: 0,
};

// The default items to be shown per page in a paginated table.
export const DEFAULT_ITEMS_PER_PAGE_IN_PG_TABLE = 20;

// The default items to be shown per page in the audit logs list.
export const DEFAULT_ITEMS_PER_PAGE_IN_AUDIT_LOGS = 100;

export const MAX_OPEN_ENDED_FIELD_OPTIONS = 16;
export const MAX_SINGLE_CHOICE_OPTIONS = 32;
export const MIN_SINGLE_CHOICE_OPTIONS = 1;
export const MAX_MULTI_CHOICE_OPTIONS = 32;
export const MIN_MULTI_CHOICE_OPTIONS = 1;
export const MAX_RANKING_OPTIONS = 32;
export const MIN_RANKING_OPTIONS = 1;
export const MAX_GRID_ROWS = 32;
export const MAX_GRID_COLUMNS = 10;
export const DEFAULT_GRID_ROW_SIZE = 4;
export const DEFAULT_GRID_COLUMN_SIZE = 1;
export const MAX_GRID_ROW_AND_COLUMN_HEADER_TEXT_CHARACTER_LIMIT = 128;
export const MAX_QUESTION_TEXT_CHARACTER_LIMIT = 512;
export const MAX_OPTION_TEXT_CHARACTER_LIMIT = 512;
export const MAX_FIELD_TITLE_CHARACTER_LIMIT = 128;
export const MAX_FIELD_HINT_CHARACTER_LIMIT = 128;
export const MAX_OPTION_AND_QUESTION_CODE_CHARACTER_LIMIT = 64;
export const MAX_OPEN_ENDED_RESPONSE_LIMIT = 10000;
export const MAX_GROUP_CHILD_QUESTIONS = 16;
export const MAX_OPEN_ENDED_RESPONSE_FIELD_ROWS = 4;
export const MAX_OPEN_ENDED_FILTER_CHARACTER_COUNT = 100;
export const MAX_APPLIED_FILTER_COUNT = 5;
export const SEVEN_DAYS_IN_MILLISECONDS = 7 * 24 * 60 * 60 * 1000;

// Updated grid constants
export const GRID_ROW_COLUMN_HEADER_HORIZONTAL_PADDING_IN_PIXELS = 48;
export const GRID_DROPDOWN_HORIZONTAL_PADDING_IN_PIXELS = 72;
export const SINGLE_SELECT_CARD_GAP_IN_PIXELS = 12;
export const GRID_COLUMN_OPTION_GAP_IN_PIXELS = 12;

// Regex for validating allowed characters in input fields.
// It checks for alphanumeric characters along with `-`.
// It also allows whitespace characters.
export const QUESTION_OPTION_CODE_ALLOWED_CHARACTERS = /^[A-Za-z0-9 -]*$/;
// Used to show the max character count after the string has reached a certain percentage.
export const SHOW_MAX_CHARACTER_COUNT_DECIMAL_PERCENTAGE = 0.8;

// Used for validating the CSV file.
export const SHA_256_LENGTH = 64;
export const MIN_FILE_NAME = 1;
export const MAX_FILE_NAME = 500;
export const MAX_RESPONDENTS_FILE_SIZE_IN_BYTES = 50000000;
export const MIN_FILE_SIZE_IN_BYTES = 50;
export const MAX_SURVEY_RULES_FILE_SIZE_IN_BYTES = 25000000;
export const MAX_COLUMN_SIZE_FOR_RESPONDENTS = 200;
export const MAX_ROW_SIZE_FOR_SURVEY_RULES = 512;
export const MAX_ROW_SIZE_FOR_RESPONDENT_RECORDS = 3000;
export const SINGLE_MEGA_BYTE_IN_BYTES = 1000000;
export const MAX_COLUMN_SIZE_FOR_TRANSLATIONS = 10;
export const MAX_TRANSLATIONS_FILE_SIZE_IN_BYTES = 50000000;
export const MAX_TRANSLATION_LANGUAGE_LIMIT = 8;

export const CSV_FILE_TYPE = ["text/csv", "appication/csv"];
export const CLIENT_LOGO_FILE_TYPE = ["image/png", "image/jpeg"];
export const RTE_SUPPORTED_FILE_TYPES = [
  "image/png",
  "image/jpg",
  "image/jpeg",
];
export const RESPONDENT_CSV_TEMPLATE_NAME = "respondent_template.csv";
export const RESPONDENTS_CSV_NAME = "respondents.csv";
export const TEMPLATE_FILE_DELIMITER = "_";
export const SURVEY_RULES_CSV_TEMPLATE_NAME = "survey_rules_template.csv";
export const EXISTING_SURVEY_RULES_FILE_NAME = "rules.csv";
export const SURVEY_TRANSLATIONS_FILE_NAME = "translations.csv";

export const CSV_RESPONDENT_BASE_COLUMNS = [
  "first name",
  "last name",
  "email address",
  "phone number",
];

export const CSV_RESPONDENT_IGNORED_COLUMNS = [
  "survey",
  "survey link",
  "survey status",
  "id",
];

export const CSV_SURVEY_RULES_BASE_COLUMNS = [
  "serial no",
  "key type",
  "key",
  "value",
  "connector",
  "action",
  "target",
];

export const ICON_SIZE = {
  large: "32px",
  medium: "24px",
  default: "20px",
  small: "16px",
  extraSmall: "12px",
};
// Used in survey respondent flow
export const PRIMARY_FONT = "Inter";
export const DEFAULT_PERCENTAGE = 0;
export const CIRCULAR_PROGRESS_SIZE = 48;
export const CIRCULAR_PROGRESS_THICKNESS = 4;
export const CIRCULAR_PROGRESS_FULL = 100;

// Used in responsiveness
export const DESKTOP_SCREEN_CONDITION = "(min-width:800px)";
export const TABLET_SCREEN_CONDITION = "(min-width:600px)";
export const MOBILE_SCREEN_CONDITION = "(max-width:425px)";

export const FONT_WEIGHTS = {
  BOLD: 700,
  NORMAL: 400,
};

export const RICH_TEXT_MARKDOWN_PROPS = {
  strikethrough: true,
  italic: true,
  h1: true,
  h2: true,
  h3: true,
  h4: true,
  h5: true,
  h6: true,
  horizontalRule: true,
  orderedList: true,
  unorderedList: true,
  bold: true,
  attachment: true,
  link: true,
  iFrame: true,
};

export const EMPTY_JSON = "{}";

export const EMPTY_LIST_LENGTH = 0;

export const LIST_WITH_SINGLE_ELEMENT = 1;

export const RICH_TEXT_DEFAULT_FONT_COLOR = "label";

export const ABOUT_US_LINK = "https://insightsfeedback.com/about-us/";

export const PRIVACY_POLICY_LINK =
  "https://insightsfeedback.com/privacy-policy/";

export const GET_IN_TOUCH_LINK = "https://insightsfeedback.com/get-in-touch/";

export const LINKED_IN_LINK =
  "https://in.linkedin.com/company/feedback-insights";

export const SEND_EMAIL_LINK =
  "https://mail.google.com/mail/?view=cm&fs=1&to=it@insightsfeedback.com";

export const X_LINK = "https://x.com/FB_Insights?t=4z532D77RgFlVByJUyKWMA&s=09";

export const FEEDBACK_INSIGHTS_LINK = "https://insightsfeedback.com/";

export const DEFAULT_GRID_ROW_OPTION_WIDTH = "320px";
export const SINGLE_RATING_DEFAULT_SCALE_OPTION_WIDTH_IN_PIXELS = 132;
export const SINGLE_RATING_SCALE_WITH_TEXT_OPTION_WIDTH_IN_PIXELS = 256;
export const GRID_SINGLE_SELECT_SLIDER_CONTAINER_WIDTH = "800px";
export const GRID_SLIDER_WIDTH = "696px";
export const SLIDER_MARK_OPTION_LIMIT = 8;
export const GRID_OPEN_ENDED_FIELD_NUMBER_OF_ROWS = 4;
export const GRID_NPS_OPTION_HEIGHT_WIDTH_IN_PIXEL = 48;

// The palette colors to be used in various designs as seen here: https://www.figma.com/file/USl8dEzHVxs10KzHoEx56w/Pulse-Revamp?type=design&node-id=747-156879&mode=design&t=vSkqRyFbpUfTl3TD-0
export const RGG_PALETTE_COLORS = {
  LEFT_MOST: {
    lightMode: { unselected: "#FCC6C2", selected: "#C52432" },
    darkMode: { unselected: "#70060B", selected: "#F47873" },
  },
  FAR_LEFT: {
    lightMode: { unselected: "#F1D3D4", selected: "#9D404C" },
    darkMode: { unselected: "#5D2025", selected: "#E59C9C" },
  },
  LEFT: {
    lightMode: { unselected: "#EBD9DD", selected: "#8A4E59" },
    darkMode: { unselected: "#542D32", selected: "#DDAFB1" },
  },
  MIDDLE: {
    lightMode: { unselected: "#DFE5EE", selected: "#636A73" },
    darkMode: { unselected: "#42474C", selected: "#CED3DA" },
  },
  RIGHT: {
    lightMode: { unselected: "#CAE3D6", selected: "#3E705A" },
    darkMode: { unselected: "#2A4436", selected: "#99C6AB" },
  },
  FAR_RIGHT: {
    lightMode: { unselected: "#BFE3CB", selected: "#2C734E" },
    darkMode: { unselected: "#1E432B", selected: "#7FBF93" },
  },
  RIGHT_MOST: {
    lightMode: { unselected: "#ABE2B3", selected: "#087935" },
    darkMode: { unselected: "#064016", selected: "#4AB264" },
  },
};

export const SINGLE_SELECT_NPS_COLOR_RANGE = {
  MAX_RED: 6,
  MAX_ORANGE: 8,
};

export const RPC_LOADER_DELAYED_TIME_IN_MS = 500;

export const PRIMARY_COLORS = {
  500: "#43254A",
  400: "#6F3E7C",
  300: "#9A58AB",
  200: "#E2D0E7",
  100: "#F8F3F9",
};

// This token lies between primary 100 and primary 200.
export const VIEWED_SURVEY_LINK_COLOR_TOKEN = "#F1E7F3";

export const LINE_CHART_GRADIENT_COLORS = {
  dark: "#9A58AB80",
  light: "#FFFFFF00",
};

export const MAX_SUBMISSION_DATA_SET_SIZE = 45;

// "projectAndSurveyNameRegex" regex is being used in Pulse-2.0/WS/commons/src/SurveyAndProjectValidation.kt.
//  Hence, any update made to "projectAndSurveyNameRegex" should be applied in Pulse-2.0/WS/commons/src/SurveyAndProjectValidation.kt as well.
//  The validation is being done in the FE only to prevent unnecessary RPC calls.
export const projectAndSurveyNameRegex =
  "^(?=.*[a-zA-Z])[a-zA-Z0-9-_(){}\\[\\]\\s]+$";

export const BASE_QUESTION_ORDER = 1;
export const MAX_RESPONDENT_NAME_LENGTH = 200;
export const MAX_RESPONDENT_EMAIL_ID_LENGTH = 500;
export const MAX_RESPONDENT_PHONE_NUMBER_LENGTH = 50;
export const MAX_RESPONDENT_COLUMN_LENGTH = 200;

// Used in survey settings screen.
export const MAX_SURVEY_NAME_LENGTH = 128;
export const MIN_SURVEY_NAME_LENGTH = 4;
export const MAX_DIALOG_TITLE_TEXT_LENGTH = 64;
export const MIN_DIALOG_TITLE_TEXT_LENGTH = 2;
export const MAX_DIALOG_DESCRIPTION_TEXT_LENGTH = 256;
export const MIN_DIALOG_DESCRIPTION_TEXT_LENGTH = 4;
export const MAX_BUTTON_TEXT_LENGTH = 32;
export const MIN_BUTTON_TEXT_LENGTH = 2;
export const MAX_MISCELLANEOUS_TEXT_LENGTH = 256;
export const MIN_MISCELLANEOUS_TEXT_LENGTH = 4;
export const MAX_SURVEY_INFORMATION_DIALOG_DESCRIPTION_TEXT_LENGTH = 8192;
export const MAX_CLOSED_SURVEY_ERROR_TITLE_TEXT_LENGTH = 8192;
export const MAX_CLOSED_SURVEY_ERROR_DESCRIPTION_TEXT_LENGTH = 8192;
export const MAX_WELCOME_PAGE_TEXT_LENGTH = 16384;
export const MAX_THANK_YOU_PAGE_TEXT_LENGTH = 16384;
export const MIN_RTE_TEXT_LENGTH = 2;
export const DEFAULT_PROJECT_LIST_SORT_INDEX = 0;
export const MAX_GENERIC_ERROR_MESSAGE_TITLE_LENGTH = 128;
export const MAX_GENERIC_ERROR_MESSAGE_DESCRIPTION_LENGTH = 512;
export const MAX_SURVEY_LIMIT = 16;

export const PROJECT_SURVEY_NAME_VALIDATIONS = {
  minLength: 4,
  maxLength: 128,
  allowedSpecialCharacters: "(){}[]-_",
};

export const EMBED_NAME_VALIDATIONS = {
  allowedCharactersRegex: "^(?=.*[a-zA-Z])[a-zA-Z0-9-_(){}\\[\\]\\s]+$",
  allowedSpecialCharacters: "(){}[]-_",
  minLength: 4,
  maxLength: 128,
};

export const EMBED_CODE_VALIDATIONS = {
  minLength: 4,
  maxLength: 1000,
};

export const MAX_EMBED_COUNT_PER_PROJECT = 16;

export const MAX_CLIENT_LOGO_FILE_SIZE_IN_BYTES = 5000000;
export const MIN_CLIENT_LOGO_FILE_SIZE_IN_BYTES = 10000;
export const PNG_FILE_EXTENSION = ".png";
export const JPG_FILE_EXTENSION = ".jpg";
export const MAX_CLIENT_LOGO_FILE_SIZE_IN_MB = 5;
export const MIN_CLIENT_LOGO_FILE_SIZE_IN_KB = 10;
export const BIG_SCREEN_CLIENT_LOGO_HEIGHT = "56px";
export const BIG_SCREEN_MAX_CLIENT_LOGO_WIDTH = "200px";

export const ASTERISK = "*";

export const DATE_FORMAT = "dd MMM, yyyy";
export const FULL_WIDTH = "100%";
export const MIN_PASSWORD_CHAR_LIMIT = 8;
export const MAX_PASSWORD_CHAR_LIMIT = 128;
export const MAX_RESPONDENT_SPOC_LIMIT = 16;
export const DARK_THEME_BACKGROUND_ELEVATED_LEVEL_1 = "#191C1F";
export const REGEX_FOR_EMAIL_VALIDATION =
  "^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^-]+(?:\\.[a-zA-Z0-9_!#$%&'*+/=?`{|}~^-]+)*@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)+$";

// These values must be in sync with the userName RPC type validations. This is added to have separate validations for the first name and the last name.
export const MAX_USERNAME_CHAR_LIMIT = 200;
export const MIN_USERNAME_CHAR_LIMIT = 1;
export const FIRST_AND_LAST_NAME_REGEX = "^[A-Za-z\\s]*$";

export const GENERATE_GENERIC_LINK_BANNER_LIFE_SPAN_IN_MS = 5000;
export const IFRAME_REGEX = /<iframe[^>]*>.*<\/iframe>/;
export const RESPONSE_RATE_MAX_DECIMAL_PLACES = 2;

export const RESET_PASSWORD_EMAIL_WAIT_DURATION_IN_MINUTES = 15;
export const MAX_QUESTION_TITLE_WIDTH_IN_PIXELS = 1080;
export const MAX_QUESTION_TITLE_WIDTH_FOR_SMALL_LAPTOP_SCREEN = "800px";

export const DEFAULT_OPEN_ENDED_COLUMN_HEADER_WIDTH = "408px";
// If in future GRID_SINGLE_MULTI_SELECT_ITEM_WIDTH_IN_PIXELS is updated, then GRID_MULTI_SELECT_WIDTH should be updated accordingly.
// Currently the horizontal padding is 20px which makes the total width to be 20px + 144px + 20px = 184px.
export const GRID_SINGLE_MULTI_SELECT_ITEM_WIDTH_IN_PIXELS = 144;
export const GRID_MULTI_SELECT_WIDTH = "184px";
export const MOBILE_RATING_SCALE_MIN_WIDTH_IN_PIXELS = "280px";
export const MAXIMUM_SINGLE_SELECT_RATING_SCALE_SLIDER_OPTIONS = 12;
export const MAXIMUM_GRID_COLUMN_OPTIONS = 12;
export const SINGLE_SELECT_NPS_OPTIONS_COUNT = 11;
export const BIG_SCREEN_HORIZONTAL_PADDING_IN_PIXELS = 80;
